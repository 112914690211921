<template>
  <div class="activityAudit app-container merch-card">
    <div class="rightBtn">
      <div style="display: flex">
        <div
          v-for="(item, index) in statusList"
          :key="index"
          class="defaultStyle"
          :class="queryParams.status === item.id ? 'opp' : ''"
          @click="StatusValue(item)"
        >
          {{ item.Name }}
        </div>
      </div>
    </div>

    <el-form ref="queryForm" :model="queryParams" :inline="true">
      <el-form-item>
        <el-input
          v-model="queryParams.name"
          placeholder="请输入业务员姓名"
          clearable
          size="small"
          style="width: 240px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="queryParams.accountName"
          placeholder="请输入账户名"
          clearable
          size="small"
          style="width: 240px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="queryParams.phone"
          placeholder="请输入手机号"
          clearable
          size="small"
          style="width: 240px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="queryParams.bankName"
          placeholder="请输入到账银行"
          clearable
          size="small"
          style="width: 240px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>

      <el-form-item>
        <el-input
          v-model="queryParams.bankNo"
          placeholder="请输入到账银行卡"
          clearable
          size="small"
          style="width: 240px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handleQuery" style="width: 100px"
          >查询</el-button
        >
        <el-button type="primary" @click="handleExportList" style="width: 100px"
          >导出列表</el-button
        >
      </el-form-item>
    </el-form>

    <!-- 列表 -->
    <el-table
      border
      :data="acticommList.list"
      style="width: 100%"
      v-loading="loading"
      :header-cell-style="{ background: '#F2F3F5' }"
      height="480"
    >
      <el-table-column align="center" label="序号" width="70">
        <template slot-scope="scope">
          <p>{{ scope.row.id }}</p>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center" width="120">
        <template slot-scope="scope">
          <div slot="placeholder">
            <p v-if="scope.row.examineStatus == 0">待审核</p>
            <p v-if="scope.row.examineStatus == 1">待打款</p>
            <p v-if="scope.row.examineStatus == 2">已打款</p>
            <p v-if="scope.row.examineStatus == 3">已驳回</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="业务员姓名"
        width="100"
        align="center"
      >
        <template slot-scope="scope">
          <div class="infor">
            {{ scope.row.name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="account" label="绑定帐号" align="center">
        <template slot-scope="scope">
          <div class="infor">
            <div>{{ scope.row.account }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="所属团队" prop="team" />
      <el-table-column align="center" label="手机号码" prop="phone">
        <template slot-scope="scope">
          <div>
            {{ scope.row.phone }}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="账户名" prop="accountName">
        <template slot-scope="scope">
          <div>
            {{ scope.row.accountName }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        label="佣金提现金额(元)"
        prop="withdrawAmount"
        min-width="100"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <div>
            {{ scope.row.withdrawAmount }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="佣金提现时间"
        prop="createTime"
        sortable
      >
        <template slot-scope="scope">
          <div>
            {{ scope.row.createTime }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="手续费(元)"
        prop="withdrawCommission"
        sortable
      />
      <el-table-column align="center" label="到账银行" prop="bankName" />
      <el-table-column align="center" label="到账银行卡号" prop="bankNo" />
      <el-table-column
        align="center"
        label="打款金额(元)"
        prop="paymentAmount"
      />

      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="scope">
          <span
            class="btsed"
            v-if="scope.row.examineStatus == 0"
            @click="handlereview(scope.row)"
            >审核</span
          >
          <span
            class="btsed"
            v-if="
              scope.row.examineStatus == 0 ||
              scope.row.examineStatus == 1 ||
              scope.row.examineStatus == 2 ||
              scope.row.examineStatus == 3
            "
            @click="handlDetail(scope.row, scope.row.id)"
            >明细</span
          >
          <span
            class="btsed"
            v-if="scope.row.examineStatus == 1"
            @click="handlPayment(scope.row)"
            >确认打款</span
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 底部数据 -->
    <div class="march-list">
      <div>
        佣金提现总金额:
        <span>{{ totalAmount.totalWithdrawAmount }} 元</span>
      </div>
      <div>
        手续费总金额:
        <span>{{ totalAmount.totalWithdrawCommission }} 元</span>
      </div>
      <div>
        打款总金额:
        <span>{{ totalAmount.totalArrivalAmount }} 元</span>
      </div>
    </div>

    <!-- 分页 -->
    <div class="merch-page">
      <el-pagination
        background
        :current-page="acticommList.pageNum"
        @current-change="handleCurrentChange"
        :page-size="acticommList.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="acticommList.total"
      >
      </el-pagination>
    </div>

    <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogVisible" width="500px">
      <el-form :model="ruleForm" ref="ruleForm" status-icon :rules="rules">
        <div class="dialogList">
          <p>业务员姓名:{{ toExamineList.name }}</p>
          <p>所属团队:{{ toExamineList.team }}</p>
        </div>
        <el-divider></el-divider>
        <div class="dialogList">
          <p>账户名:{{ toExamineList.accountName }}</p>
          <p>佣金提现申请金额:{{ toExamineList.withdrawAmount }}</p>
        </div>
        <el-divider></el-divider>
        <div class="dialogList">
          <p>佣金提现时间:{{ toExamineList.createTime }}</p>
          <p>手续费:{{ toExamineList.withdrawCommission }}</p>
        </div>
        <el-divider></el-divider>
        <div class="dialogList">
          <p>到账银行:{{ toExamineList.bankName }}</p>
          <p>到账银行卡号:{{ toExamineList.bankNo }}</p>
        </div>
        <el-divider></el-divider>
        <div class="dialogList">
          <p>打款金额:{{ toExamineList.withdrawAmount }}</p>
        </div>
        <el-divider></el-divider>
        <div>
          <span style="margin-right: 10px">审核意见:</span>
          <el-radio-group v-model="ruleForm.radioStatus">
            <el-radio :label="1">审核通过</el-radio>
            <el-radio :label="3">审核驳回</el-radio>
          </el-radio-group>
        </div>
        <el-form-item
          label="驳回原因"
          v-if="ruleForm.radioStatus == 3"
          prop="rejectReason"
        >
          <el-input
            style="margin-top: 20px"
            type="textarea"
            autosize
            :rows="2"
            maxlength="200"
            placeholder="请输入内容"
            v-model="ruleForm.rejectReason"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handlereviewNo">取 消</el-button>
        <el-button
          type="primary"
          @click="handlereviewOk(toExamineList.id)"
          :loading="btnloading"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 确认打款 -->
    <el-dialog
      title="确认打款"
      :visible.sync="dialogPaymentVisible"
      :close-on-click-modal="false"
      width="25%"
    >
      <!-- <el-divider></el-divider> -->
      <div>确定对该业务员确认打款吗？</div>
      <div slot="footer" class="text-right" style="margin-top: -20px">
        <el-button @click="dialogPaymentVisible = false">取 消</el-button>
        <el-button type="primary" @click="okPayment(2)" class="back_green bai"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
// const { mapActions, mapState } = createNamespacedHelpers("Basics"); //vuex公共库
import { downLoadZip } from "@/utils/zipdownload";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("Audit"); //vuex公共库
export default {
  name: "activityAudit",
  data() {
    return {
      btnloading: false,
      activeName: "",
      // 加载状态
      loading: false,
      dialogVisible: false,
      dialogPaymentVisible: false, // 打款弹窗
      ruleForm: {
        radioStatus: 1,
        rejectReason: "",
      },

      textarea1: "",
      // 总条数
      total: 1,
      datelist: [],
      //活动商品状态
      statusList: [
        { Name: "全部", id: null },
        { Name: "待审核", id: 0 },
        { Name: "待打款", id: 1 },
        { Name: "已打款", id: 2 },
        { Name: "已驳回", id: 3 },
      ],
      //商品分类
      commstate: [],
      queryParams: {
        currPage: 1,
        perPage: 10,
        name: "", //业务员姓名
        phone: "", //手机号
        accountName: "", //账户名称
        bankName: "", //到账银行
        bankNo: "", //银行卡卡号
        status: null,
        operatorId: sessionStorage.getItem("admin_id"), //操作人id
        operator: this.$cookie.get("newUserName"), //操作人姓名
      },
      acticommList: [],
      toExamineList: {},
      totalAmount: {
        totalWithdrawAmount: "",
        totalWithdrawCommission: "",
        totalArrivalAmount: "",
      },
      paymentId: "",
      salesmanId: "",
      rules: {
        rejectReason: [
          { required: true, message: "驳回原因不能为空!", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.queryParams = this.detailsSeach[router];
      this.datelist = this.detailsSeach[router].datelist;
    }
    this.getList();
    this.postWithdrawMoney();
  },
  computed: {
    ...commonIndex.mapState(["allJurisdiction", "detailsSeach"]),
  },

  methods: {
    handleClick(tab, event) {},
    ...mapActions([
      "postWithdrawList",
      "postwithdrawUpdateStatus",
      "postQueryWithdrawMoney",
      "postWithdrawExport",
    ]),
    ...commonIndex.mapMutations(["changeSeach"]),
    // 查询提现审核列表
    async getList() {
      this.loading = true;
      let res = await this.postWithdrawList(this.queryParams);
      this.loading = false;
      this.acticommList = res.content;
      this.total = res.content.total;
      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.queryParams };
      save.datelist=this.datelist
      save.router = router;
      this.changeSeach(save);
    },
    // 查询列表佣金总金额
    async postWithdrawMoney() {
      let res = await this.postQueryWithdrawMoney(this.queryParams);
      if (res.code == "000000") {
        // this.totalAmount = res.content
        if (res.content != null) {
          this.totalAmount.totalArrivalAmount = res.content.totalArrivalAmount;
          this.totalAmount.totalWithdrawAmount =
            res.content.totalWithdrawAmount;
          this.totalAmount.totalWithdrawCommission =
            res.content.totalWithdrawCommission;
        } else {
          this.totalAmount.totalArrivalAmount = "/";
          this.totalAmount.totalWithdrawAmount = "/";
          this.totalAmount.totalWithdrawCommission = "/";
        }
      }
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.currPage = 1;
      this.getList();
      this.postWithdrawMoney();
    },
    // 导出
    handleExportList() {
      let queryParams = this.queryParams;
      downLoadZip(
        `/mgengine/withdraw/examine/export`,
        "xlsx",
        "佣金提现导出",
        queryParams,
        "app",
        "post"
      );
    },
    //确认打款
    async okPayment() {
      this.dialogPaymentVisible = false;
      let res = await this.postwithdrawUpdateStatus({
        id: this.paymentId,
        status: 2,
        operatorId: sessionStorage.getItem("admin_id"), //操作人id
        operator: this.$cookie.get("newUserName"), //操作人姓名
        salesmanId: this.salesmanId,
        money: this.totalAmount.totalArrivalAmount,
      });
      if (res.code == "000000") {
        this.$message({
          message: res.message,
          type: "success",
        });
        this.getList();
      } else {
        this.$message({
          message: res.message,
          type: "warning",
        });
      }
      // this.getList();
    },
    /** 点击按钮标记 */
    StatusValue(item) {
      this.queryParams.status = item.id;
      this.queryParams.currPage = 1;
      this.getList();
      this.postWithdrawMoney();
    },
    /** 审核记录 */
    handlereview(row) {
      this.dialogVisible = true;
      this.toExamineList = row;
    },
    //提交审核或者驳回
    async handlereviewOk(id) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (
            this.ruleForm.radioStatus == 3 &&
            this.ruleForm.rejectReason == ""
          ) {
            this.$message({
              message: "带*号的为必填,审核意见不能为空!",
              type: "warning",
            });
            return;
          }
          this.btnloading = true;
          this.postwithdrawUpdateStatus({
            id: id,
            status: this.ruleForm.radioStatus,
            rejectReason: this.ruleForm.rejectReason,
            operatorId: sessionStorage.getItem("admin_id"), //操作人id
            operator: this.$cookie.get("newUserName"), //操作人姓名
            salesmanId: this.toExamineList.salesmanId,
            money: this.totalAmount.totalArrivalAmount,
          })
            .then((res) => {
              this.btnloading = false;
              this.dialogVisible = false;
              if (res.code == "000000") {
                this.$message({
                  message: res.message,
                  type: "success",
                });
                this.getList();
              } else {
                this.$message({
                  message: res.message,
                  type: "warning",
                });
              }
            })
            .catch((err) => {
              this.btnloading = false;
            });
        }
      });
    },

    // 放弃更改
    handlereviewNo() {
      this.dialogVisible = false;
      this.ruleForm.rejectReason = "";
    },

    /** 明细 */
    handlDetail(row, val) {
      sessionStorage.setItem("acticommList", JSON.stringify(row));
      this.$router.push({
        path: "/WithdrawalDetails",
        query: { id: val },
      });
    },
    /**打款 */
    handlPayment(row) {
      this.paymentId = row.id;
      this.salesmanId = row.salesmanId;
      this.dialogPaymentVisible = true;
    },
    async handleCurrentChange(e) {
      this.queryParams.currPage = e;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.march-list {
  width: 40%;
  height: 48px;
  line-height: 48px;
  color: #606266;
  display: flex;
  justify-content: space-between;
}
.dialogList {
  display: flex;
  justify-content: space-between;
  p {
    width: 50%;
  }
}
.app-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}
.el-form-item {
  margin-bottom: 10px;
}
.sign {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}
.opp {
  color: #06b7ae;
  border-radius: 5px;
  font-weight: bold;
  background-color: rgba($color: #2e3238, $alpha: 0.05);
}
.defaultStyle {
  width: 100px;
  padding: 10px 20px;
  text-align: center;
  line-height: 100%;
  font-size: 14px;
  cursor: pointer;
}
::v-deep .el-input--small .el-input__inner {
  height: 40px;
  line-height: 40px;
}
::v-deep .el-form--inline .el-form-item {
  margin-right: 2%;
  margin-top: 1%;
}
// ::v-deep .el-date-editor .el-range-separator{
//   padding: 0;
// }
</style>
